const baseURL = `https://reporting.pdfpro.com/api`;

export async function Login({ data }) {
  const user = {
    email: data.email.trim(),
    password: data.password.trim(),
  };

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const response = await fetch(`${baseURL}/login`, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getOrders(start, end, token, store) {
  const queryParams =
    start && end
      ? `?dateStart=${start}&dateEnd=${end}&token=${token}`
      : `?token=${token}`;
  const response =
    store === ""
      ? await fetch(`${baseURL}/read${queryParams}`)
      : store === "PDF Pro"
      ? await fetch(`${baseURL}/read${queryParams}`)
      : await fetch(`${baseURL}/read-legacy${queryParams}`);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getRefunds(start, end, token) {
  const queryParams =
    start && end
      ? `?dateStart=${start}&dateEnd=${end}&token=${token}`
      : `?token=${token}`;

  const response = await fetch(`${baseURL}/read-refunds${queryParams}`);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function downloadOrdersCSV(start, end, fileName = false, token) {
  const queryParams =
    start && end
      ? `?dateStart=${start}&dateEnd=${end}&token=${token}`
      : `?token=${token}`;
  const response = await fetch(`${baseURL}/export${queryParams}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  response.blob().then((blob) => {
    if (blob.type.includes("json")) {
      alert("No data in required date range!");
    } else {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = !fileName ? "OrdersCSV.csv" : fileName;
      a.click();
    }
  });
}

export async function downloadRefundsCSV(start, end, fileName = false, token) {
  const queryParams =
    start && end
      ? `?dateStart=${start}&dateEnd=${end}&token=${token}`
      : `?token=${token}`;
  const response = await fetch(`${baseURL}/export-refunds${queryParams}`);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  response.blob().then((blob) => {
    if (blob.type.includes("json")) {
      alert("No data in required date range!");
    } else {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = !fileName ? "RefundsCSV.csv" : fileName;
      a.click();
    }
  });
}
